import React from "react"
import Layout from "../components/layout"
import Contactpage from "../components/Contact"

const Contact = () => (
  <Layout>
    <Contactpage/>
  </Layout>
)

export default Contact
