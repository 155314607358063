import PropTypes from "prop-types"
import React from "react"
import {Helmet} from "react-helmet"
import { Link } from "gatsby"
import { Container, Row, Col, Navbar, NavbarBrand, button } from 'reactstrap'
import InputMask from 'react-input-mask'
import { navigate } from 'gatsby'
import axios from 'axios'
import logo from "../../images/logo.svg" 
import close_icon from "../../images/close_icon.svg" 
// import 'bootstrap/dist/css/bootstrap.css';
 
import "../global.scss"
import "./style.scss"

class Contact extends React.Component{ 
  constructor (props) {
    super(props)
    this.state = {
        firstName: '',
        lastName: '',
        email:'',
        phoneNumber:'',
        CurrentWebsite:'',
        firstNameError: '',
        lastNameError: '',
        emailError:'',
        phoneNumberError:'',
        CurrentWebsiteError:'',
        validationFunctionCall:false,
        email_error:'',
        website_error:'',
        button:true,
        buttonDisable:false,
        message:'',
        confirmMessage:''
    }
  }

  handleInputChange = event => {
      const target = event.target
      const value = target.value
      const name = target.name
      this.setState({ [name]: value,},()=>{
        if (this.state.validationFunctionCall){
          this.validate()
        }
      })
  }


  validate =()=>{
    var errorFlag = true
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression)

    if(!this.state.firstName){
      errorFlag = false
      this.setState({ firstNameError : true })
    } else{
      this.setState({ firstNameError : false })
    }
    if(!this.state.lastName){
      errorFlag = false
      this.setState({ lastNameError : true })
    } else{
      this.setState({ lastNameError : false })
    }
    if (this.state.email === '' || this.state.email.trim() === '') {
      errorFlag = false
      this.setState({ emailError : true ,email_error : 'This Field is Required'})
    }
    if (this.state.email && this.state.email !== '') {
      let testR = emailRegex.test(String(this.state.email).toLowerCase())
      if (!testR) {
        errorFlag = false
        this.setState({ emailError : true ,email_error : 'Email is invalid.'})
      } else{
        this.setState({ emailError : false ,email_error : ''})
      }
    }
    if((this.state.phoneNumber).replace(/[^0-9]/g,"").length < 10) {
      errorFlag = false
      this.setState({ phoneNumberError : true })
    } else{
      this.setState({ phoneNumberError : false })
    }
    if (this.state.CurrentWebsite) {
      let testR = (this.state.CurrentWebsite).match(regex) 
      if(!testR){
        errorFlag = false
        this.setState({ CurrentWebsiteError : true , website_error : 'Website is invalid.'})
      } else{
        this.setState({ CurrentWebsiteError : false , website_error : ''})
      }
    } else{
      this.setState({ CurrentWebsiteError : false , website_error : ''})
    }

    return errorFlag
  }

  handleSubmit = event => {
      event.preventDefault()
      this.setState({ validationFunctionCall : true })
      let contacts ,number 
      let ApiUrl = "https://stagapi.spacelet.com/api/yardPost/userContactDetail"
      const errorFlag = this.validate()

      /*if(this.state.phoneNumber){
        number = (this.state.phoneNumber).replace(/[^\d]/g, ''); 
      }*/

      if(errorFlag){
        this.setState({button:false ,buttonDisable:true })
        contacts ={ 
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email:this.state.email,
          phoneNumber:this.state.phoneNumber,
          CurrentWebsite:this.state.CurrentWebsite ? this.state.CurrentWebsite : 'N/A',
        }
        
        axios.post(`${ApiUrl}`,{ contacts })
          .then( (response) =>{
            if(response.data.success){
              return navigate('/thankyou')
              this.setState({ firstName : '', lastName:'', email:'', phoneNumber:'' ,CurrentWebsite:'' ,validationFunctionCall: false , button:true ,buttonDisable:false, message:response.data.message, confirmMessage:true})
              setTimeout(()=>{
                this.setState({ message: '', confirmMessage:'' })
              },5000)
            } else {
              this.setState({ message: response.data.message , buttonDisable:false, button:true, confirmMessage:false  })
              setTimeout(()=>{
                this.setState({ message: '', confirmMessage:'' })
              },5000)
            }

          })
          .catch((error) =>{
            console.log(error)
          })
      }
  }

  render(){
    return(
      <div>  
    <div className="contact__wrapper" id="contact">
      <Helmet>
        <title>Contact Us | Yardpost</title>
        <meta name="description" content="A standout web presence, a simple CRM, easy to use marketing tools, and a powerful listing management platform with IDX and marketplace syndication all built in and all in one place." />
      </Helmet>
      <header>
        <Container>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Navbar  light expand="md">
                <NavbarBrand href="/"><img className="logo_image" src={logo}/></NavbarBrand>
                <Link to={'/'} className="ml-auto"><img className="close_icon" src={close_icon}/></Link>
              </Navbar>
            </Col>
          </Row>    
        </Container>
      </header>
      <section className="signup__form-wrapper">
        <div className="signup__form-inner">
          <div className="signup__form-description text-center">
            <h1 className="form__title">
            Get Notified
          </h1>
          <p>Find out as soon as we launch and gain early, discounted access:</p>
          </div> 
          <form>  
                <div className="form__input-group">               
                    <div className="form-group">
                      <div className="material_input"> 
                        <label className="pure-material-textfield-outlined">
                           <input className="form-control" type="text"  name="firstName" value={this.state.firstName} onChange={this.handleInputChange} placeholder=" "/>
                          <span className="input__placeholder">First Name</span>
                        </label>
                        {this.state.firstNameError ? (<p className="error__text">This Field is Required</p>) : ''}
                      </div>
                    </div>              
                    <div className="form-group">
                      <div className="material_input"> 
                        <label className="pure-material-textfield-outlined">
                          <input className="form-control" type="text" name="lastName"  value={this.state.lastName} onChange={this.handleInputChange} placeholder=" "/>
                          <span className="input__placeholder">Last Name</span>
                        </label>
                        {this.state.lastNameError ? (<p className="error__text">This Field is Required</p>) : ''}
                      </div>
                    </div>          
                    <div className="form-group">
                      <div className="material_input"> 
                        <label className="pure-material-textfield-outlined">
                          <input className="form-control" type="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder=" "/>
                          <span className="input__placeholder">Work Email</span>
                        </label>
                        {this.state.emailError ? (<p className="error__text">{this.state.email_error}</p>) : ''}
                      </div>
                    </div> 
                    <div className="form-group">
                      <div className="material_input"> 
                        <label className="pure-material-textfield-outlined">
                          <InputMask mask='(999) 999-9999'className="form-control" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleInputChange} placeholder=" "/>
                          <span className="input__placeholder">Mobile Number</span>
                        </label>
                        {this.state.phoneNumberError ? (<p className="error__text">This Field is Required</p>) : ''}
                      </div>
                    </div> 
                    <div className="material_input"> 
                      <label className="pure-material-textfield-outlined">
                        <input type="text" className="form-control" name="CurrentWebsite" value={this.state.CurrentWebsite} onChange={this.handleInputChange} placeholder=" "/>
                        <span className="input__placeholder">Current Website (optional)</span>
                      </label>
                      {this.state.CurrentWebsiteError ? (<p className="error__text">{this.state.website_error}</p>) : ''}
                    </div>
                    {/*<div className="material_input">
                      <select className="form-control" id="exampleFormControlSelect1">
                        <option>Current Website (optional)</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>*/}
                </div>           
                <button onClick={this.handleSubmit.bind(this)} disabled={this.state.buttonDisable} className="btn btn-primary btn-block submit__btn">{this.state.button ? 'Sign Up' : 'Please Wait...'}</button>
                <p className={this.state.confirmMessage ? "text_success" : "text_error"}>{this.state.message}</p>
                <p className="text__info">We’ll only send you launch-related updates and never hand your contact info over to anyone else.</p>
              </form>
        </div>
      </section>
    </div>
  </div>
    )
  }
}

Contact.propTypes = {
  siteTitle: PropTypes.string,
}

Contact.defaultProps = {
  siteTitle: ``,
}

export default Contact